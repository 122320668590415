<template>
  <v-container id="SCADENZE" fluid tag="section">
    <v-row>
      <v-col :cols="isEditScadenze ? '4' : '12'">
        <base-material-card
          color="white"
          iconcolor="darkred"
          icon="mdi-calendar-alert"
          :title="$t('page.scadenze.titolo')"
          class="px-5 py-3"
        >
          <v-container>
            <v-row>
              <v-col>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('scadenze.ricerca')"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
              </v-col>
              <v-col align="end">
                <v-btn 
                    color="darkred" 
                    width="180px"
                    @click="onExportGrid">
                    <v-icon left> mdi-export </v-icon>
                    {{ $t("esporta") }}
                  </v-btn>

              </v-col>
            </v-row>
          </v-container>
          <v-data-table
            style="max-height:600px; overflow:auto"
            :items="scadenze.source"
            :headers="headersColumns"
            :search="search"
            item-key="veicoloTarga"
            @click:row="clickTableRow"
            :items-per-page="-1"
            single-select
            :loading="isloading_scadenze"
            class="elevation-1"
            loading-text="Caricamento dati in corso..."
          >
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import Utils from "@/utils/Utils";
import UtilsDate from "@/utils/UtilsDate";

import ScadenzeService from "@/service/ScadenzeService";
import ExcelService from "@/service/ExcelService";

@Component
export default class Scadenze extends Vue {
  scadenze = {
    select: [],
    selected: null,
    source: [],
  };
 
  headersColumns = [
    {
      text: "Data scadenza",
      align: "start",
      value: "dataCorrenteText",
    },
    { text: "Km Attuali", value: "kmAttuali" },
    { text: "Km Scadenza", value: "nKmOreCor" },
    { text: "Automezzo (Attrezzatura)", value: "nomeVeicoloAttrez" },
    { text: "Intervento richiesto", value: "interventoTipo.cDescrizione" },
    { text: "Tipo scadenza", value: "scadenzaTipo.cDescrizione" },
    { text: "Note", value: "xNote" },
  ];

  
  // Variabili
  isEditScadenze = false;
  isCreatedCorrectly = false;
  isloading_scadenze = false;

  search = "";

  created() {
    let verLoadingPage = Utils.verifyLoadingPage(this.$store, "R_SCADENZE");
    switch (verLoadingPage)
    {
      case 0: // All OK
        this.isCreatedCorrectly = true
        this.loadScadenzeAttive();
        break;
      
      case 1: // Utente non loggato
        AuthService.logout();
        break;

      case 2: // Ente non selezionato
        this.$router.push({ name: "Dashboard" });
        break;

      case 3: // Permesso alla pagina non presente
        this.$router.push({ name: "Dashboard" });
    }

  }

  clickTableRow(scad, row) {
    this.scadenze.selected = scad;
    //this.isEditScadenze = true;
  }

  closeDetail() {
    this.isEditScadenze = false;
  }

  loadScadenzeAttive() {
    var self = this;
    var enteId = this.$store.state.enteSel.id;
    self.isloading_scadenze = true;
    ScadenzeService.getScadenzeAttive(
      enteId,
      (resp) => {
        console.log("GetScadenzeAttive");
        console.log(resp);
        
        self.scadenze.source = resp;
        for (let i = 0; i < self.scadenze.source.length; i++) {
          var item = self.scadenze.source[i];
          //item.dScadenza = UtilsDate.convertStringToDateIta(item.dDecorrenza);
          item.dScadenza = UtilsDate.convertStringToDateIta(item.dCorrente);
        }
        self.isloading_scadenze = false;
      },
      (err) => {
        self.isloading_scadenze = false;
        console.log("Errore GetScadenzeAttive");
        console.log(err);
        return null;
      }
    );
  }

  onExportGrid() {
    ExcelService.generaExcelScadenze(
      function(data, resp) {
          const blob = new Blob([data]);
          const el = document.createElement("a");
          el.href = window.URL.createObjectURL(blob);
          const regex = /filename=(?<filename>[^,;]+);/g;
          const match = regex.exec(resp.headers['content-disposition']);
          let filename = ""; // or any other value you consider default
          if (typeof match !== 'undefined' && match !== null) {
              filename = match.groups['filename'];
          }
          el.download = filename;
          el.click();
        },
        (fail) => {
          alert(fail);
        },
    )
  }
}
</script>

<style>

</style>